<template>
  <div>

    <!--section Banner-->
    <diapo/>
    <!--TypeWater-->
    <section>
      <div id="rowAnimation">
        <v-row class="d-flex justify-center mb-6 gridpad">
              <v-col  cols="12" lg="4">
                <div class="scroll-textes" ref="observedCampaign" id="campaign" :class="{ 'is-visible': campaignVisible  }">
                  <v-row>
                    <v-col> 
                      <div class="txt-c"><router-link to="/filtre_a_eau" class="sectionTypeWaterLink hover">{{ $t('typeService.residentialTitle') }}</router-link></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="bgSectionTypeWaterPhoto1"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="txt-j pa-2">
                      <p v-html="$t('typeService.residentialText')"></p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" lg="4">
                <div class="scroll-textes" ref="observedTown" id="town" :class="{ 'is-visible': townVisible }">
                  <v-row>
                    <v-col><div class="txt-c"><router-link to="/analyseEau" class="sectionTypeWaterLink hover">{{ $t('typeService.urbanTitle') }}</router-link></div></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="bgSectionTypeWaterPhoto2"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="txt-j pa-2">
                      <p v-html="$t('typeService.urbanText')"></p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col cols="12" lg="4">
                <div class="scroll-textes" ref="observedWaterMachine" id="waterMachine"  :class="{ 'is-visible': waterMachineVisible }">
                  <v-row>
                    <v-col><div class="txt-c"><router-link to="/laveuse_haute_pression" class="sectionTypeWaterLink hover">{{ $t('typeService.pressureTitle') }}</router-link></div></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="bgSectionTypeWaterPhoto3"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="txt-j pa-2">
                      <p v-html="$t('typeService.pressureText')"></p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
        </v-row>
      </div>

      <v-row class="d-flex justify-center mb-6 gridpad">
        <v-col>
          <div class="scroll-textes-right" ref="observedAgricultural" id="agricultural" :class="{ 'is-visible': agriculturalVisible }">
            <v-row>
              <v-col>
                <div class="titleAgricole"><router-link to="/pompes_agricoles_commerciales_industrielles"  class="sectionTypeWaterLink hover">{{ $t('typeService.aCIndustrialTitle') }}</router-link></div>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col cols="12" lg="4">
                <div class="bgSectionTypeWaterPhoto4"></div>
              </v-col>
              <v-col class="txt-j">
                  <p class="textACTop" v-html="$t('typeService.aCIndustrialText')"></p>
              </v-col>
            </v-row>
          </div>
        </v-col>
        
      </v-row>
    </section>
    <!--Forage-->
    <section>
      <v-row>
        <v-col>
          <div style="width: 100%; height: auto; margin-bottom: -20px;">
            <img style="width: inherit; height: inherit;" src="/Resources/images/publicity/PubForage.jpg"/>
          </div>
          
        </v-col>
      </v-row>
    </section>
    <!--v1.2 Pumps-->
    <section>  
      <pumps/>
    </section>
    <!--Team-->
    <section class="bgSectionTeam">
      <v-row class="d-flex justify-center">
        <v-col cols="12"  lg="6" class="py-0">
          <div class="bgSectionTeamPhoto1"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j sectionTeamTextPad">
          <div class="scroll-textes-up" ref="observedTeam" id="team"  :class="{ 'is-visible': teamVisible }">
            <h2 class="sectionTeamTitle">{{ $t('teamSection.Title') }}</h2>
            <h3 class="sectionTeamSubtitle pb-30">{{ $t('teamSection.Slogan') }}</h3>
            <p class="pb-30" v-html="$t('teamSection.Text')"></p>
            <router-link class="button" to="/experts" ><p class="buttonText" v-html="$t('teamSection.Link1')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    
    <!--v1.2 Skills-->
    <section style="">
      <services/>
    </section>

    <!--Distribution-->
    <distributors/>
  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .pb-30 {padding-bottom: 30px;}
  .gridpad2 {padding: 0px 100px;}
  .hover:hover {color: rgb(var(--primary));}
  .textACTop{transform: translate(0%,50%);}
  .fade-in-enter-active, .fade-in-leave-active { transition: all 1s ease; }
  .fade-in-enter-from, .fade-in-leave-to { opacity: 0; transform: translateY(-104px); }
  .fade-in-enter-to, .fade-in-leave-from { opacity: 1; transform: translateY(0); }

  /*#region TypeWater*/ 
  .sectionTypeWaterLink {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .bgSectionTypeWaterPhoto1 {background-image: url("../../public/Resources/images/Home/Residentielle-Traitement.jpg"); height:315.78px; background-position: center; background-repeat: no-repeat; background-size: cover; }
  .bgSectionTypeWaterPhoto2 {background-image: url("../../public/Resources/images/Home/Rurale-Traitement.jpg"); height:315.78px; background-position: center ; background-repeat: no-repeat; background-size: cover; }
  .bgSectionTypeWaterPhoto3 {background-image: url("../../public/Resources/images/Products/pressureWasher2.jpg"); height:315.78px; background-position: center center; background-repeat: no-repeat; background-size: cover; }
  .bgSectionTypeWaterPhoto4 {background-image: url("../../public/Resources/images/Home/Industrielle-Traitement.jpg"); height:315.78px; background-position: center; background-repeat: no-repeat; background-size: cover; }
  .titleAgricole { text-align: left;}
  /*#endregion*/ 

  /*#region Team*/ 
  .sectionTeamTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .sectionTeamSubtitle {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 22px;}
  .bgSectionTeam {background-color: rgb(var(--tierce2));}
  .bgSectionTeamPhoto1 {background-image: url("../../public/Resources/images/Home/equipe-bisson2.jpg"); height:700px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .sectionTeamTextPad { padding: 225px 200px 0px 45px;}
  /*#endregion*/ 

  /*#region Skills*/ 
  .skillLink {height:75px; font-size: 22px; vertical-align: middle; color: rgb(var(--primary)) !important;}
  .skillLink:hover {color: rgb(var(--secondary)) !important;}
  .skillItem {height: 100%; width: 100%; max-width: 400px; min-width: 350px; position: relative; display: block; margin: auto auto !important;}
  .itemHover {opacity:0;}
  .itemHover:hover {opacity:1;}
  
  /*#endregion*/ 

  /*#region Quality*/ 
  .sectionQualityTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .bgSectionQuality {background-color: rgb(var(--tierce2));}
  .sectionQualityLink {font-size: 22px; color: rgb(var(--secondary));}
  .sectionQualityLink:hover {color: rgb(var(--primary));}
  .sectionQualityTextPadLeft { padding: 175px 45px 0px 200px; background-color: rgb(var(--tierce2));}
  .sectionQualityTextPadRight { padding: 100px 200px 100px 45px; background-color: rgb(var(--tierce2));}
  /*#endregion*/ 

  /*#region Analyse*/ 
  .sectionAnalyseTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .sectionAnalyseSubtitle {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 22px;}
  .bgSectionAnalyse {background-color: rgb(var(--tierce)); margin:12px 0px 12px 0px;}
  .bgSectionAnalysePhoto {background-image: url("../../public/Resources/images/Home/analyse.jpg"); height:725px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .sectionAnalyseTextPad { padding: 225px 75px 0px 200px;}
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {}

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .textACTop{transform: unset;}

    
  }

  @media handheld, only screen and (max-width: 1279px) {
    .titleAgricole { text-align: center;}
    .bgSectionTeamPhoto1 {height:300px; background-position-y: 33%; background-repeat: no-repeat; background-size:150%;}
    .sectionTeamTextPad {padding: 25px 30px 30px 30px;}
    .gridpad2 {padding: 0px 25px 50px 25px;}
    .sectionQualityTextPadLeft { padding: 50px 35px 0px 35px; background-color: rgb(var(--tierce2));}
    .sectionQualityTextPadRight { padding: 15px 35px 50px 35px; background-color: rgb(var(--tierce2));}
    .bgSectionAnalysePhoto {height:300px; background-position-y: 45%; background-repeat: no-repeat; background-size:cover;}
    .sectionAnalyseTextPad { padding: 15px 45px 0px 45px;}
  }
  @media handheld, only screen and (max-width: 1378px) {

  }

</style>

<script>
    import pageTranslations from '../../public/Resources/i18N/home.json'
    import distributors from '../components/distributors.vue';
    import diapo from '../components/diapo.vue';
    import pumps from '../components/pumps.vue';
    import services from '../components/services.vue';
  
    export default {
    name: 'Home',
    data: () => ({
      Skills: [
        {
          icon: '/Resources/images/icon/Analyse-deau.png',
          hoverIcon: '/Resources/images/icon/Analyse-deau_hover.png',
          link: '/analyseEau',
          linktitle: 'skillsSection.emergency'
        },
        {
          icon: '/Resources/images/icon/traitement-eau.png',
          hoverIcon: '/Resources/images/icon/traitement-eau_hover.png',
          link: '/filtre_a_eau',
          linktitle: 'skillsSection.treatmentResidential'
        },
        {
          icon: '/Resources/images/icon/Pompe-a-eau.png',
          hoverIcon: '/Resources/images/icon/Pompe-a-eau_hover.png',
          link: '/pompe_a_eau_residentielle',
          linktitle: 'skillsSection.treatmentAgricultural',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur_hover.png',
          link: '/reparation_pompe_de_piscine',
          linktitle: 'skillsSection.treatmentCommercial',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur-electriqueV2-Noir.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur-electriqueV2.png',
          link: '/reparation_moteurs_electriques',
          linktitle: 'skillsSection.repairPump',
        },
        {
          icon: '/Resources/images/icon/pompe-industrielle.png',
          hoverIcon: '/Resources/images/icon/pompe-industrielle_hover.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'skillsSection.repairEngine',
        },
        {
          icon: '/Resources/images/icon/Lavage-a-pression_icon_noir.png',
          hoverIcon: 'Resources/images/icon/Lavage-a-pression_icon.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'skillsSection.artesianWell',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'skillsSection.pressureController',
        }
      ],
      Pumpes: [
        {
          icon: '/Resources/images/icon/Analyse-deau.png',
          hoverIcon: '/Resources/images/icon/Analyse-deau_hover.png',
          link: '/analyseEau',
          linktitle: 'Pompe submersible'
        },
        {
          icon: '/Resources/images/icon/traitement-eau.png',
          hoverIcon: '/Resources/images/icon/traitement-eau_hover.png',
          link: '/filtre_a_eau',
          linktitle: 'Pompe égout'
        },
        {
          icon: '/Resources/images/icon/Pompe-a-eau.png',
          hoverIcon: '/Resources/images/icon/Pompe-a-eau_hover.png',
          link: '/pompe_a_eau_residentielle',
          linktitle: 'Pompe puisard',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur_hover.png',
          link: '/reparation_pompe_de_piscine',
          linktitle: 'Pompe piston',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur-electriqueV2-Noir.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur-electriqueV2.png',
          link: '/reparation_moteurs_electriques',
          linktitle: 'Pompe prominent',
        },
        {
          icon: '/Resources/images/icon/pompe-industrielle.png',
          hoverIcon: '/Resources/images/icon/pompe-industrielle_hover.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'Pompe sécurité',
        },
        {
          icon: '/Resources/images/icon/Lavage-a-pression_icon_noir.png',
          hoverIcon: 'Resources/images/icon/Lavage-a-pression_icon.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'Pompe circulation chauffage',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe diaphragme',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe transfert',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe piscine',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe spa',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe irrigation',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe booster pompe',
        }
      ],
      campaignVisible: false,
      townVisible: false,
      waterMachineVisible: false,
      agriculturalVisible: false,
      teamVisible: false,
    }),
    async mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
      await this.createObserver();
    },
    methods: {
      async createObserver() {
            // Options pour l'IntersectionObserver
            const options = { threshold: 0.5, }; // La section est visible lorsqu'elle est à 50% dans la vue
            
            // Créer un nouvel observateur Service
            const observer = new IntersectionObserver(this.handleIntersectionServices, options);       
            const targetCampaign = this.$refs.observedCampaign;
            const targetTown = this.$refs.observedTown;
            const targetWaterMachine = this.$refs.observedWaterMachine;
            const targetAgricultural = this.$refs.observedAgricultural;
            const targetTeam = this.$refs.observedTeam;

            if (targetCampaign) { observer.observe(targetCampaign);}
            if (targetTown) { observer.observe(targetTown);  }
            if (targetWaterMachine) { observer.observe(targetWaterMachine);  }
            if (targetAgricultural) { observer.observe(targetAgricultural);  }
            if (targetTeam) { observer.observe(targetTeam);  }
      },
      handleIntersectionServices(entries) {
        entries.forEach((entry) => {
          let e = entry.target.id;
          switch (e) {
            case 'campaign':
              if (entry.isIntersecting) { this.campaignVisible = true; } 
              else { this.campaignVisible = false; }
              break;
            case 'town':
              if (entry.isIntersecting) { this.townVisible = true; } 
              else { this.townVisible = false; }
              break;

            case 'waterMachine':
              if (entry.isIntersecting) { this.waterMachineVisible = true; } 
              else { this.waterMachineVisible = false; }
              break;
            
            case 'agricultural':
              if (entry.isIntersecting) { this.agriculturalVisible = true; } 
              else { this.agriculturalVisible = false; }
              break;
            
            case 'team':
              if (entry.isIntersecting) { this.teamVisible = true; } 
                else { this.teamVisible = false; }
                break;
              
            default:
              break;
          }
        });
      },
    },
    components: {
      distributors,
      diapo,
      pumps,
      services
    },
  }
</script>
