<template>
    <v-app>
    <v-main>
        <Cookie/>
        <noscript v-if="authorizations && authorizations.Advertising == true"><iframe src="//www.googletagmanager.com/ns.html?id=GTM-5PXL23C8" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <cHeader />
      <div class="fixHeader"></div>
      <div style="">
        <router-view/>
      </div>     
      <cFooter />
    </v-main>
  </v-app>

  
</template>

<style>
  .fixHeader {height: 85px;}
  @media handheld, only screen and (max-width: 600px) {
    .fixHeader {height: 40px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .fixHeader {height: 30px;}
  }
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
  import Cookie from '../src/components/cookie.vue';
  export default {
    name: 'App',
    data: () => ({
      authorizations:{
          Essential: true,
          Performance: false,
          Personalization: false,
          Advertising: false,
      },
    }),
    mounted: async function () {
    this.VisibilityTools();
  },
  methods:{
    VisibilityTools: function(){
      let getAuthorizations = JSON.parse(localStorage.getItem("authorizationCookies"));
      if(getAuthorizations) { this.authorizations = JSON.parse(localStorage.getItem("authorizationCookies")); } 
      if(this.authorizations) {
        if(this.authorizations.Advertising == true) { GoogleAdvertising(window,document,'script','dataLayer','GTM-5PXL23C8'); }
        else if(this.authorizations.Performance == true) { GooglePerformance('G-86F1LTW6D1'); }
      }
      
      
    }
  },
    components: {
      Cookie,
    }

  }
</script>
