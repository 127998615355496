<template>
  <div >
    <!--Section1 Banner-->
    <section>
      <div class="topBanner"></div>
      <v-row class="bannerTextPad">
        <v-col cols="12">
          <div class="txt-c ">
            <img class="iconBanner" src="/Resources/images/icon/reparation-moteur.png"/>
          </div>
          <h1 class="txt-c bannerTitle">Réparation et entretien de pompes à eau </h1>
        </v-col>
      </v-row>
    </section>
    <!-- Why Section -->
    <section>
      <transition :duration="1050" name="fade-in">
        <div id="AnimationOne" name="AnimationOne" v-if="showAnimationOne">
          <v-row class="gridpad2 d-flex justify-center" style="margin: 20px auto ;">
            <v-col class="boiteWhySection" style="">
              <div class="overBox" >
                <v-col @click="navigateJoin" style="position: relative; height: 100%; width: 100%;">
                  <h2 class="overBoxTitle">Nous contacter</h2>
                </v-col>
              </div>
              <v-row><v-col><h2 class="TitleWhySection">Intervention rapide et efficace </h2></v-col></v-row>
              <v-row><v-col><p class="textWhySection">Nous réagissons rapidement à vos besoins d'urgence pour minimiser l'interruption de vos activités. Nous offrons le service d’appel d’urgence.</p></v-col></v-row>
            </v-col>
            <v-col class="boiteWhySection" style="">
              <div class="overBox" >
                <v-col @click="navigateJoin" style="position: relative; height: 100%; width: 100%;">
                  <h2 class="overBoxTitle">Nous contacter</h2>
                </v-col>
              </div>
              <v-row><v-col><h2 class="TitleWhySection">Techniciens qualifiés</h2></v-col></v-row>
              <v-row><v-col><p class="textWhySection">Notre équipe est composée de techniciens hautement qualifiés et expérimentés dans la gestion des systèmes de pompage et dans la réparation de ceux-ci.</p></v-col></v-row>
            </v-col>
            <v-col class="boiteWhySection" style="">
              <div class="overBox" >
                <v-col @click="navigateJoin" style="position: relative; height: 100%; width: 100%;">
                  <h2 class="overBoxTitle">Nous contacter</h2>
                </v-col>
              </div>
              <v-row><v-col><h2 class="TitleWhySection" >Satisfaction garantie</h2></v-col></v-row>
              <v-row><v-col><p class="textWhySection">Nous nous engageons à fournir un service de haute qualité qui répond à vos attentes et aux normes en vigueur.</p></v-col></v-row>
            </v-col>
          </v-row>
        </div>
      </transition>
    </section>
    <!-- Chose Section -->
    <section>
      <v-row class="">
        <v-col class="txt-j section-c">
          <div class="chosePad">
            <h2 class="choseTitle mb-2">Pourquoi opter pour une réparation Bisson Service</h2>
            <p class="choseText">Une bonne réparation de vos pompes n’est pas seulement une question de maintien de fonctionnement; c'est une stratégie essentielle pour assurer la durabilité, la sécurité de votre approvisionnement en eau potable, chauffage et évacuation des eaux usées. Voici pourquoi choisir des services de réparation de qualité est crucial :</p>
            <ul class="ulpad">
              <li><h3 class="choseliTitle" >Durabilité accrue</h3> <p class="choseliText"> : Des réparations bien effectuées prolongent la vie de vos pompes, ce qui signifie moins de remplacements et de coûts à long terme.</p></li>
              <li><h3 class="choseliTitle" >Efficacité améliorée</h3> <p class="choseliText"> : Une pompe correctement réparée fonctionne à son meilleur niveau d'efficacité, ce qui peut aider à réduire les coûts d'énergie.</p></li>
              <li><h3 class="choseliTitle" >Prévention des pannes majeures</h3> <p class="choseliText"> : Des interventions régulières permettent de détecter et de corriger les petits problèmes avant qu'ils ne deviennent de grandes pannes, évitant ainsi des arrêts imprévus.</p></li>
              <li><h3 class="choseliTitle" >Sécurité</h3> <p class="choseliText"> : Les équipements défectueux peuvent poser des risques de sécurité. Des réparations adéquates garantissent que les équipements fonctionnent en toute sécurité pour les utilisateurs et l'environnement.</p></li>
              <li><h3 class="choseliTitle" >Conformité réglementaire</h3> <p class="choseliText"> : Certaines industries sont soumises à des réglementations strictes concernant la maintenance de leurs équipements. Des réparations professionnelles assurent la conformité aux normes en vigueur.</p></li>
              
            </ul>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--  Sector repair pompe -->
    <section>
      <transition :duration="1050" name="fade-in">
        <div id="AnimationTwo" name="AnimationTwo">
          <v-row class="">
            <v-col class="txt-j section-c">
              <div class="mapPad">
                <h2 class="mapTitle txt-r">Réparation de pompe par secteur</h2>
                <v-row class="mt-10 ">
                  <v-col class="boxSectorRepair">
                    <div style="border: 5px solid rgb(var(--primary)); height: 100%;">
                      <div><img class="sectorImg" style="" src="/Resources/images/Services/agricole.jpg" /></div>
                      <div>
                        <div class="circleImg">
                          <img  style="height: 100%; width: auto; object-position: center;" src="/Resources/images/Pumps/pompeIrrigation.jpg" />
                        </div>
                      </div>
                      <div style="position: relative; z-index: 100; padding: 20px; ">
                        <div style="">
                          <v-row><v-col><h2 class="txt-c SectorTitle">Agricole</h2></v-col></v-row>
                          <v-row>
                            <v-col>
                              <h3 class="choseliTitle">Protégez votre exploitation avec des services de réparation fiables. </h3>
                              <p class="choseliText">Les pompes et traitement d’eau jouent un rôle crucial dans l’approvisionnement en eau pour vos installations. Bisson Service propose une expertise spécifique pour réparer et maintenir les systèmes utilisés dans l'agriculture, assurant ainsi le bon fonctionnement de votre exploitation tout au long de l'année.</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ul class="ulpadSector">
                                <li><h3 class="choseliTitle" >Maintenance préventive</h3> <p class="choseliText"> : Pour éviter les pannes pendant les périodes critiques.</p></li>
                                <li><h3 class="choseliTitle" >Réparation rapide</h3> <p class="choseliText"> : Minimiser les temps d'arrêt avec des interventions rapides et efficaces.</p></li>
                                <li><h3 class="choseliTitle" >Nouvelle installation</h3> <p class="choseliText"> : Vous avez un projet Bisson Service est la solution tout incluse pour vous.</p></li>
                              </ul>
                            </v-col>
                          </v-row>
                        </div>
                        
                      </div>
                    </div>

                  </v-col>
                  <v-col class="boxSectorRepair">
                    <div style="border: 5px solid rgb(var(--primary)); height: 100%;">
                      <div><img class="sectorImg" style="" src="Resources/images/Services/industrial.jpg" /></div>
                      <div>
                        <div class="circleImg">
                          <img  style="height: 100%; width: auto; object-position: center;" src="Resources/images/Pumps/turbinePump.jpg" />
                        </div>
                      </div>
                      <div style="position: relative; z-index: 100; padding: 20px; ">
                        <div style="">
                          <v-row><v-col><h2 class="txt-c SectorTitle">Industrielles</h2></v-col></v-row>
                          <v-row>
                            <v-col>
                              <h3 class="choseliTitle">Maximisez l'efficacité opérationnelle avec des solutions de maintenance expertes. </h3>
                              <p class="choseliText">Les industries dépendent fortement des systèmes de pompages pour leurs processus de production. Bisson Service offre des services de réparation, de maintenance et d’installation pour maintenir vos systèmes de pompage à leur niveau optimal de performance.</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ul class="ulpadSector">
                                <li><h3 class="choseliTitle" >Analyses et diagnostics avancés</h3> <p class="choseliText"> : Pour éviter les pannes pendant les périodes critiques.</p></li>
                                <li><h3 class="choseliTitle" >Réparations sur mesure</h3> <p class="choseliText"> : Adaptées aux exigences spécifiques de vos équipements industriels.</p></li>
                                <li><h3 class="choseliTitle" >Conseils techniques</h3> <p class="choseliText"> : Notre équipe de conseillés expérimentés est-là pour vous proposer de nouveaux systèmes de pompage pour améliorer votre ligne de production.</p></li>
                              </ul>
                            </v-col>
                          </v-row>
                        </div>
                        
                      </div>
                    </div>

                  </v-col>
                  <v-col class="boxSectorRepair">
                    <div style="border: 5px solid rgb(var(--primary)); height: 100%;">
                      <div><img class="sectorImg" style="" src="Resources/images/Services/commercial.jpg" /></div>
                      <div>
                        <div class="circleImg">
                          <img  style="height: 100%; width: auto; object-position: center;" src="Resources/images/Pumps/turbinePump.jpg" />
                        </div>
                      </div>
                      <div style="position: relative; z-index: 100; padding: 20px; ">
                        <div style="">
                          <v-row><v-col><h2 class="txt-c SectorTitle">Secteur Commercial et institutionnel</h2></v-col></v-row>
                          <v-row>
                            <v-col>
                              <h3 class="choseliTitle">Assurez le confort et la continuité des opérations dans votre établissement. </h3>
                              <p class="choseliText">Que ce soit pour les systèmes de chauffage, de refroidissement ou de traitement des eaux usées, une pompe défectueuse peut affecter l'ensemble de vos opérations. Bisson Service comprend l'importance de pompes fiables dans le secteur et offre des réparations rapides pour éviter toute interruption.</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ul class="ulpadSector">
                                <li><h3 class="choseliTitle" >Service client réactif</h3> <p class="choseliText"> : Intervention rapide pour toutes urgences.</p></li>
                                <li><h3 class="choseliTitle" >Solutions durables</h3> <p class="choseliText"> : Prolonger la durée de vie de vos équipements avec des réparations de qualité.</p></li>
                              </ul>
                            </v-col>
                          </v-row>
                        </div>
                        
                      </div>
                    </div>

                  </v-col>
                  <v-col class="boxSectorRepair">
                    <div style="border: 5px solid rgb(var(--primary)); height: 100%;">
                      <div><img class="sectorImg" style="" src="Resources/images/Services/residential.jpg" /></div>
                      <div>
                        <div class="circleImg">
                          <img  style="height: 100%; width: auto; object-position: center;" src="Resources/images/Pumps/pompePiston.jpg" />
                        </div>
                      </div>
                      <div style="position: relative; z-index: 100; padding: 20px; ">
                        <div style="">
                          <v-row><v-col><h2 class="txt-c SectorTitle">Résidentielles</h2></v-col></v-row>
                          <v-row>
                            <v-col>
                              <h3 class="choseliTitle">Gardez votre maison confortable et fonctionnelle avec des services de réparation d’experts certifiés. </h3>
                              <p class="choseliText">Les pompes résidentielles, qu'elles soient pour votre puits ou votre système de chauffage, elles nécessitent une attention particulière pour fonctionner efficacement. Bisson Service offre un service de réparation à domicile pour s'assurer que votre système domestique reste en parfait état.</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ul class="ulpadSector">
                                <li><h3 class="choseliTitle" >Évaluations complètes</h3> <p class="choseliText"> : Vérifier l'état général et l'efficacité du système de pompage.</p></li>
                                <li><h3 class="choseliTitle" >Réparations efficaces</h3> <p class="choseliText"> : Résoudre les problèmes rapidement pour rétablir le confort de votre foyer.</p></li>
                              </ul>
                            </v-col>
                          </v-row>
                        </div>
                        
                      </div>
                    </div>

                  </v-col>

                </v-row>

              </div>
            </v-col>
          </v-row>
        </div>
      </transition>

    </section>
    <!--map zon-->
    <section id="map" class="">
      <v-row class="">

        <v-col class="txt-j textMap">
          <div class="mapPad">
            <h2 class="mapTitle mb-2">Zone Desservie</h2>
            <p>Chez Bisson Service, notre engagement est de fournir une assistance rapide et fiable en réparation de pompes dans un large rayon de 160 km autour de Drummondville. Voici un aperçu des régions que nous desservons activement :</p>
            <ul class="ulpad">
              <li><h3 class="mapliTitle" >Drummondville</h3> <p class="mapliText"> : Au coeur de notre activité, nous répondons promptement à tous les appels locaux.</p></li>
              <li><h3 class="mapliTitle" >Saint-Hyacinthe</h3> <p class="mapliText"> : Une présence forte dans cette région permet de répondre rapidement aux demandes de nos clients agricoles, résidentiels et commerciaux.</p></li>
              <li><h3 class="mapliTitle" >Trois-Rivières</h3> <p class="mapliText"> : Nos services sont complets pour les secteurs résidentiel, agricole, et commercial, assurant une couverture efficace pour tous vos besoins.</p></li>
              <li><h3 class="mapliTitle" >Centre-du-Québec</h3> <p class="mapliText"> : Une présence forte dans cette région permet de répondre rapidement aux demandes de nos clients agricoles, résidentiels et commerciaux.</p></li>
              <li><h3 class="mapliTitle" >Sorel-Tracy</h3> <p class="mapliText"> : Nous intervenons rapidement pour des urgences et des maintenances programmées, offrant une assistance de qualité à divers secteurs.</p></li>
              <li><h3 class="mapliTitle" >Saint-Jean-sur-Richelieu</h3> <p class="mapliText"> : Notre service étendu couvre tous les besoins en réparation de pompes, que ce soit pour des résidences, des exploitations agricoles ou des entreprises.</p></li>
              <li><h3 class="mapliTitle" >Estrie</h3> <p class="mapliText"> : Nous sommes présents dans cette région pour tous vos besoin résidentiel, agricole, commercial et industriel.</p></li>
            </ul>
          </div>
        </v-col>        
        <v-col class="pa-0 d-flex align-center justify-center mapZoneVCol">
          <div style="float:right; " class="mapImg" ></div>
        </v-col> 
      </v-row>
    </section>
    <!--Form contact-->
    <section class="bgForm gridpadForm">
      <v-row>
        <v-col><h2 class="subtitle">Nous contacter</h2></v-col>
      </v-row>
      <v-row>
          <v-col cols="12">
            <form  ref="form">
              <!--Form-->
              <v-row>
                  <v-col style="">

                    <!--name-->
                    <v-row class="">

                      <v-col class="fieldMin">
                        <v-row class="padForm">
                          <p class="bold f-secondary" v-if="!error.name" style="font-size: 14px;" >Nom:</p>
                          <p class="bold error" v-if="error.name" style="font-size: 14px;">Nom:</p>
                        </v-row>
                        <v-row class="padForm">
                          <v-text-field @change="error.name = false" id="name" name="name" type="text" v-model="item.name" bg-color="blue-lighten-5" clearable required />
                          <p class="errorText" v-if="error.name" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                        </v-row>
                      </v-col>

                      <!--email-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Courriel</p></v-row>
                        <v-row class="padForm">
                          <v-text-field id="Courriel" name="Courriel" type="text" v-model="item.email" bg-color="blue-lighten-5" clearable required />
                        </v-row>
                      </v-col>
                   

                      <!--city-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Addresse</p></v-row>
                        <v-row class="padForm">
                          <v-text-field id="Ville" name="Ville" type="text" v-model="item.location" bg-color="blue-lighten-5" clearable required />
                        </v-row>
                      </v-col>

                      <!--phone-->
                      <v-col class="fieldMin">
                        <v-row class="padForm">
                          <p class="bold f-secondary" v-if="!error.phone" style="font-size: 14px;">Téléphone</p>
                          <p class="bold error" v-if="error.phone" style="font-size: 14px;">Téléphone</p>
                        </v-row>
                        <v-row class="padForm">
                          <v-text-field id="Telephone" name="Telephone" type="text" label="xxx xxx-xxxx" v-model="item.phone" bg-color="blue-lighten-5" clearable required />
                          <p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis **</p>
                        </v-row>
                      </v-col>
                      <!--problems-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Service voulu</p></v-row>
                        <v-row class="padForm">
                          <v-select id="Problematiques" name="Problematiques" type="text" clearable chips v-model="item.problems" bg-color="blue-lighten-5" :items="['Réparation pompe à eau','Entretien pompe à eau']" multiple required />
                        </v-row>
                      </v-col>
                      <!--Description-->
                      <v-col class="fieldFormMax">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Description</p></v-row>
                        <v-row class="padForm">
                          <v-textarea clearable required no-resize id="description" name="description" v-model="item.Message" label="Description"  bg-color="blue-lighten-5" rows="7" row-height="45" single-line :counter="520" maxlength="520"/>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>


              </v-row>

              <!--accept form-->
              <v-row class="d-flex flex-lg-row flex-column">
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-center justify-start">
                  <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" >Il y a eu un probleme dans la demande veuillez recommencer</p>
                </v-col>
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-end justify-center">
                  <v-btn v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button" style="margin-right:15px;">
                    <span class="buttonText2" v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" >Envoyer</span>
                    <span class="buttonText2" v-if="this.Dialog == 'pending'">veuillez patientez pour l'envoie du formulaire</span>
                  </v-btn>
                  <h3 id="ReparePumpFormSent" v-if="this.Dialog == 'send'" class="f-primary" style="text-align: center; font-size: 2em;">La demande à été envoyer !</h3>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>

    </section>
  </div>
</template>
<style scoped>
  .minBoxImg { min-width: 525px; }
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .gridpad2 {padding: 0px 50px;}
  .gridpad3 {padding: 0px 350px;}
  .iconFormat {width: 50px; height: 50px; margin-bottom: -15px;}
  .subtitle {line-height: 35px;}
  .fade-in-enter-active, .fade-in-leave-active { transition: all 1s ease; }
  .fade-in-enter-from, .fade-in-leave-to { opacity: 0; transform: translateY(-104px); }
  .fade-in-enter-to, .fade-in-leave-from { opacity: 1; transform: translateY(0); }
  /*#region section Banner*/ 
    .topBanner {height: 450px; background-image: url('../../../public/Resources/images/WaterAnalysis/banner_analysis.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
    .bannerTextPad {padding:45px 0px 60px 0px;}
    .bannerTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
    .iconBanner {width: 50px; height: 50px; margin-bottom: -10px;}
  /*#endregion*/ 
  /*#region Form*/
    .gridpadForm {padding: 50px 200px;}
      .bgForm {background-color: rgb(var(--tierce2));}
      .fieldMin { min-width: 50%; }
      .fieldFormMax { min-width: 100%; }
      .padForm { padding: 5px 10px;}
  /*#endregion*/ 
  /*#region section why section*/ 
    .boiteWhySection { position: relative; max-width: 400px; padding: 20px; margin: 0px 20px;  box-shadow: 0px 0px 10px 2px rgb(var(--primary)); }
    .boiteWhySection:hover .overBox { opacity: 1; cursor: pointer;}
    .overBox { position: absolute; left: 0; top:0; height: 100%; width: 100%; z-index: 10; background-color: rgba(000, 000, 000, 0.5); opacity: 0;}
    .overBoxTitle { position: absolute; color: #fff; top:50%; left:50%; transform: translate(-50%,-50%); font-weight: 700; }
    .TitleWhySection { font-size: 24px; color: rgb(var(--primary)); font-weight: 700; }
    .textWhySection { text-align: justify; }
  /*#endregion*/
  /*#region Chose Section*/ 
    .ulpad {padding: 0px 100px; margin: 20px 0px; }
    .ulpad li { padding: 10px 0px; }
    .choseTitle { font-size: 24px; color: rgb(var(--secondary)); font-weight: 700;  }
    .choseText { text-align: justify; margin: 20px 0px;}
    .chosePad { max-width: 80%; margin: 50px auto; }
    .choseliTitle { display: inline; font-size: 18px; color: rgb(var(--primary)); font-weight: 700;  }
    .choseliText { display: inline; font-size: 16px; color: #000;  }
  /*#endregion*/
  /*#region MapZone*/
    .mapTitle { font-size: 24px; color: rgb(var(--secondary)); font-weight: 700; }
    .mapText { text-align: justify; margin: 20px 0px;}
    .mapPad { max-width: 90%; margin: 50px auto; }
    .ulMapPad {padding: 0px 100px; margin: 20px 0px; }
    .mapliTitle { display: inline; font-size: 18px; color: rgb(var(--primary)); font-weight: 700;  }
    .mapliText { display: inline; font-size: 16px; color: #000;  }
    .mapImg {
      height:500px; 
      width:60%; 
      background-image: url("../../../public/Resources/images/logo/map.png");
      background-position: center center; 
      background-repeat: no-repeat; 
      background-size:contain;
      transform: rotate(20deg);
      opacity: 0.4;
    }
    #map {margin: 50px 0px;}
  /*#endregion*/
  /*#region Sector repair pompe*/
    .boxSectorRepair { position: relative; overflow: hidden; display: inline-block; min-width: 45%; max-width: 50%; margin: 20px; padding: 0px; }
    .boxSectorRepair:hover { box-shadow: 0px 0px 10px 0px #000; transform: translateY(-10px); }
    .sectorImg { position: absolute; z-index: 1; height: 100%; width: 100%; top:0; right: 0; object-position: center; object-fit: cover; opacity: 0; }
    .boxSectorRepair:hover .sectorImg { opacity: 0.1;  }
    .SectorTitle { text-align: center; font-size: 24px; color: rgb(var(--secondary)); font-weight: 700; }
    .ulpadSector { padding: 0px 50px; }
    .circleImg { position: absolute; z-index: 10; top: 20px; left: 20px; width: 200px; height: 200px; border: 5px solid rgb(var(--secondary)); border-radius: 50%; opacity: 0.2; overflow: hidden; }
    .boxSectorRepair:hover .circleImg { opacity: 0; }
    .sectorPad { max-width: 90%; margin: 0px auto; }
    /*#endregion*/
@media handheld, only screen and (max-width: 600px) {
    .minBoxImg { min-width: 100%; margin-left: auto; margin-right: auto; display: block;}
    .gridpad3 {padding: 0px 0px 0px 0px !important;}
    .typeTitle {text-align: center; padding:50px 0px 0px 0px;}
}

@media handheld, only screen and (max-width: 960px) {
  .gridpad {padding: 0px 25px 50px 25px;}
  .gridpad3 {padding: 0px 0px 0px 0px }
  .boiteWhySection {min-width: 100%; margin: 20px;}
  .ulpad {padding: 0px 20px; margin: 20px 0px; } 
  .chosePad { max-width: unset; padding: 0px 25px; margin: 20px auto; }
  .mapPad { max-width: unset; padding: 0px 25px; margin: 20px auto;}
  .boxSectorRepair { min-width: 90%; max-width: 95%; }
  .mapZoneVCol { display: none !important; }
  .gridpadForm{ padding: 25px; }
  .fieldMin { min-width: 90%; max-width: 100%; }
}

@media handheld, only screen and (max-width: 1279px) {
  .gridpad2 {padding: 0px 25px;}
  .gridpad {padding: 0px 25px;}

}
@media handheld, only screen and (max-width: 1378px) {
 
}
</style>

<script>
  export default {
    name: 'repairPump',
    data: () => ({
      select2 :  {title: 'Locataire / Tenant', value: '1'},
      cart:null,
      item: {
        name: null,
        email: null,
        phone: null,
        address: null,
        location: null,
        treatment: null,
        problems: null,
        Message: null
      },
      Dialog: "notSend", //"error, send, notSend"
      error:{
        name: false,
        phone: false
      },
      key: "", //need to add key
      showForm: true,
      showAnimationOne:false,
      showAnimationTwo:false,
      
    }),
    mounted() {

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.showAnimationOne = true;
            }
          });
      }, { threshold: 0.1 }); // Se déclenche lorsque 10% de l'élément est visible

      // Observer l'élément qui contient l'image
      this.observer.observe(this.$el.querySelector('div'));

    },
    beforeDestroy() {
      this.observer.disconnect();
    },
    methods: {
      validation: async function(){
        let valid = true;
        let errorFlag = {name: false, phone: false};
        if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
        if(this.item.phone == null || this.item.phone.length < 12){valid = false; errorFlag.phone = true; }
        this.error = errorFlag;
        return valid;
      },
        login: async function () {

          let api = 'https://abstracxion.wx3data.com/security/login/BissonService'; // need Tenant BissonService 
          let data = null;
          try {
              let response = await fetch(api, {
                  method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *client
                  body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
              })
              if (await response.ok) {
                  if (await response.status !== 204) {
                      let data = await response.text();
                      return data
                  }
              } else {
                  let msg = await response.text();
                  $App.event.$emit("app_error", msg);
              };
                  
          } catch (error) {
              console.log(error);
          }
      },
        SendForm: async function (){
        this.Dialog = "pending";
        let valid = await this.validation();
        if(valid == true){
          let formData = new FormData(this.$refs.form);
          /*
          setTimeout(() => {
            this.Dialog = "send";
          }, 3000);*/ 

          let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
          try {
              let token = await this.login();

              // ** ATTENTION need to add bearer token...
              let response = await fetch(api, {
                  method: 'POST',
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Authorization': 'Bearer ' + token
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *client
                  body: formData
              });

              if (await response.ok) {
                  if (await response.status !== 204) {
                    this.Dialog = "send";
                  }
              } else {
                this.Dialog = "error";
              };

          } catch (error) {

          }
        }else { this.Dialog = 'error'; }

      },
      navigateJoin: async function(){ this.$router.push('/contact'); },
      setupObservers() {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) { this.showAnimationOne = true; }
          });
        }, { threshold: 0.1 }); // Se déclenche lorsque 10% de l'élément est visible

        /*this.observerTwo = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) { this.showAnimationTwo = true; }
          });
        }, { threshold: 0.1 }); // Se déclenche lorsque 10% de l'élément est visible*/

        // Observer l'élément qui contient l'image
        this.observer.observe(this.$el.querySelector('#AnimationOne'));
        //this.observerTwo.observe(this.$el.querySelector('#AnimationTwo'));


      }
    },
    components: {},
  }
</script>