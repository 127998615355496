<template>
    <section>
        <v-row>
            <v-col cols="12">
              <div class="boxContact">
                <div class="backgroundimg d-flex align-center justify-center">
                  <v-row >
                    <v-col style="background-color: rgb(var(--secondary)); position: absolute; z-index: 100; height: 100%; width:100%; top:0; right: 0; opacity: 80%;"></v-col>
                    <v-col style="background-image: url('/Resources/images/General/eau.gif'); background-size: cover; background-position: center center; background-repeat: no-repeat; z-index: 10; position: absolute; height: 100%; top:0; right: 0; opacity: 40%;"></v-col>
                    <v-col style="line-height: 1em; position: absolute; z-index: 1000; top:50%; left:50%; transform: translate(-50%,-50%); text-align: center;">
                      <p  class="f-tierce txtContact" v-html="$t('section.contactUs')"></p><br/>
                      <a  class="f-tierce txtContact txtphone" :href="'tel:' + $t('section.phone')" v-html="$t('section.phone')"></a>
                    </v-col>
                  </v-row>
                </div>
                
              </div>
              
            </v-col>
        </v-row>
    </section>
</template>
<style scoped>
  .boxContact {
    position:relative; 
    height:115px; width:100%;
    
    overflow: hidden;
  }
  .backgroundimg {
    position: absolute; height:115px; width:100%;
    
    background-color: rgba(var(--secondary),0.6);
  }
  .txtContact {font-size: 26px; font-weight: 700; }
  .txtphone:hover { color: rgb(var(--primary)) !important; }

  @media handheld, only screen and (max-width: 600px) {
    .txtContact {font-size: 18px !important;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .txtContact {font-size: 20px;}
  }
  @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/contactUs.json';

  export default {
    name: 'ContactUs',
    data: () => ({

    }),
    
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
    methods:{

    },
    component: {

    }
    
  }
</script>