<template>
    <section class="mb-4">
            <v-carousel class="heightDiapo" :show-arrows="false" hide-delimiters cycle infinite>
                <div class="" ref="observedFirstDiapo" id="firstDiapo">
                    <v-carousel-item src="/Resources/images/Banner/homeDrinkingWater.jpg" cover style="position:relative;">               
                        <div class="mHide "  style="position:absolute; top:40%; left:50%; transform: translate(-50%, -50%);  width:75%;">
                            <h2 class="txt scroll-textes-up" style="" :class="{ 'is-visible': firstDiapoVisible }">{{ $t('diapo.drinkingWater') }} </h2>
                        </div>
                    </v-carousel-item>
                </div>
                <div class="" ref="observedSecondDiapo" id="secondDiapo">
                    <v-carousel-item src="/Resources/images/Banner/homeTasteSmell.jpg" cover style="position:relative;">
                        <div class="mHide" style="position:absolute; top:40%; left:50%; transform: translate(-50%, -50%); width:75%" >
                            <h2 class="txt scroll-textes-right" :class="{ 'is-visible': secondDiapoVisible }">{{ $t('diapo.tasteSmell') }} </h2>
                            <v-col cols="12" class="d-flex justify-center mt-10"><router-link class="scroll-btn-right" to="/analyseEau/#TypeWater" :class="{ 'is-visible': secondDiapoVisible }"><v-btn class="btn " >{{ $t('diapo.tasteSmellBtn') }}</v-btn></router-link></v-col>
                        </div>
                    </v-carousel-item>
                </div>
                <div class="" ref="observedThirdDiapo" id="thirdDiapo">
                    <v-carousel-item src="/Resources/images/Banner/homeControl.jpg" cover style="position:relative;">
                        <div class="mHide" style="position:absolute; top:40%; left:50%; transform: translate(-50%, -50%); width:75%" >
                            <h2 class="txt scroll-textes" :class="{ 'is-visible': thirdDiapoVisible }">{{ $t('diapo.control') }} </h2>
                            <v-col cols="12" class="d-flex justify-center mt-10"><router-link class="scroll-btn-left" to="/analyseEau" :class="{ 'is-visible': thirdDiapoVisible }"><v-btn class="btn">{{ $t('diapo.controlBtn') }}</v-btn></router-link></v-col>              
                        </div>
                    </v-carousel-item>
                </div>
                <div class="" ref="observedFourDiapo" id="fourDiapo">
                    <v-carousel-item src="/Resources/images/Banner/homeQuality.jpg" cover style="position:relative;">
                        <div class="mHide" style="position:absolute; top:40%; left:50%; transform: translate(-50%, -50%); width:75%" >
                            <h2 class="txt scroll-textes-up" :class="{ 'is-visible': fourDiapoVisible }">{{ $t('diapo.quality') }} </h2>
                            <v-col cols="12" class="d-flex justify-center mt-10"><router-link class="scroll-textes" to="/pompes_agricoles_commerciales_industrielles" :class="{ 'is-visible': fourDiapoVisible }"><v-btn class="btn">{{ $t('diapo.qualityBtn') }}</v-btn></router-link></v-col>               
                        </div>
                    </v-carousel-item>
                </div>
            </v-carousel>
        

    </section>
</template>
<style scoped>
  @keyframes scale {
    0% { transform: scale(1); }
    100% { transform: scale(1.1); }
  }
    .heightDiapo {height: 600px;}
    .box {}
    .txt {
        transition: transform 1s;
        text-align: center ; font-weight: 700; line-height: 5vw; 
        font-size: 4vw ;  color: rgb(var(--secondary)); 
        text-transform: uppercase !important;  
        text-shadow: 1px 1px 3px white;
        width: 100%;
    }
    .v-carousel-item:hover .txt {
        animation: scale 10s infinite alternate;
    }
    .btn {
        color: rgb(var(--tierce)); 
        background-color: rgb(var(--primary)); 
        font-size: 16px!important;
    }
    .heightDiapo {height: 600px !important;}
    @media handheld, only screen and (max-width: 600px) {
      .heightDiapo {height: 200px !important;}
    }
    @media handheld, only screen and (max-width: 960px) {
        .btn {font-size: 12px !important;}
        .heightDiapo {height: 300px !important;}
    }
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/global.json'

  export default {
    name: 'Diapo',
    data: () => ({
        firstDiapoVisible: false,
        secondDiapoVisible: false,
        thirdDiapoVisible: false,
        fourDiapoVisible: false
    }),
    async mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
      await this.createObserverDiapo();
    },
    methods:{
        async createObserverDiapo() {
            // Options pour l'IntersectionObserver
            const options = { threshold: 0.5, }; // La section est visible lorsqu'elle est à 50% dans la vue
            
            // Créer un nouvel observateur Service
            const observer = new IntersectionObserver(this.handleIntersectionDiapo, options);       
            const targetFirstDiapo = this.$refs.observedFirstDiapo;
            const targetSecondDiapo = this.$refs.observedSecondDiapo;
            const targetThirdDiapo = this.$refs.observedThirdDiapo;
            const targetFourDiapo = this.$refs.observedFourDiapo;
            //const targetTeam = this.$refs.observedTeam;

            if (targetFirstDiapo) { observer.observe(targetFirstDiapo);}
            if (targetSecondDiapo) { observer.observe(targetSecondDiapo);  }
            if (targetThirdDiapo) { observer.observe(targetThirdDiapo);  }
            if (targetFourDiapo) { observer.observe(targetFourDiapo);  }
            //if (targetTeam) { observer.observe(targetTeam);  }
        },
        handleIntersectionDiapo(entries) {
        entries.forEach((entry) => {
          let e = entry.target.id;
          switch (e) {
            case 'firstDiapo':
              if (entry.isIntersecting) { this.firstDiapoVisible = true; } 
              else { this.firstDiapoVisible = false; }
              break;
              
            case 'secondDiapo':
              if (entry.isIntersecting) { this.secondDiapoVisible = true; } 
              else { this.secondDiapoVisible = false; }
              break;
            
            case 'thirdDiapo':
              if (entry.isIntersecting) { this.thirdDiapoVisible = true; } 
              else { this.thirdDiapoVisible = false; }
              break;
            
            case 'fourDiapo':
              if (entry.isIntersecting) { this.fourDiapoVisible = true; } 
              else { this.fourDiapoVisible = false; }
              break;

            default:
              break;
          }
        });
      },
    },
    component: {

    }
    
  }
</script>