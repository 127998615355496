<template>
    <section style="height: auto;">
      <v-row>
        <v-col cols="12">
          <div class="boxContact">
            <div class="backgroundimg d-flex align-center justify-center">
              <v-col style="background-color: rgb(var(--secondary)); position: absolute; z-index: 100; height: 100%; width:100%; top:0; right: 0; opacity: 80%;"></v-col>
              <v-col style="background-image: url('/Resources/images/General/eau.gif'); background-size: cover; background-position: center center; background-repeat: no-repeat; z-index: 10; position: absolute; height: 100%; top:0; right: 0; opacity: 40%;"></v-col>
              <v-col style="line-height: 1em; position: absolute; z-index: 1000; top:50%; left:50%; transform: translate(-50%,-50%); text-align: center;">
                <h1 class="f-tierce introTitle" >{{ $t('pump.title') }}</h1>
              </v-col>
            </div>
          </div>
          
        </v-col>
      </v-row>
      <v-carousel cycle :show-arrows="false" hide-delimiters infinite style=" padding-top: 20px;">
        <template v-for="(item, index) in Dist"> 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < Dist.length"> 
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < Dist.length"  height="100%" >
                      <v-row class="" justify="center">
                        <div class="display-3" style="transform: translate(0%,30%);">
                            <!--ici ton image ou information necessaire-->
                          <router-link :to="Dist[index + i].link" target="_blank">
                            <!--<img class="itemHover" style="position: absolute; z-index: 2; " :src="Dist[index + i].hover"/>-->
                            <img class="" style="position: relative; z-index: 1; max-width: 250px; height: auto;" :src="Dist[index + i].img"/>
                            <h2 class="txt-c f-secondary bold" v-html="Dist[index + i].title"></h2>
                          </router-link>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </section>
</template>
<style scoped>
    .titleDist {text-align: center; font-size: 26px; font-weight: 700; background-color: rgb(var(--tierce2)); color: rgb(var(--secondary)); padding:60px 0px 60px 0px;}
    .itemHover {opacity:0;}
    .itemHover:hover {opacity:1;}
    .boxContact {
    position:relative; height:150px; width:100%;
    text-align: center;   
    background-image: url('../../public/Resources/images/General/ricardo-gomez-angel-8XDfQmFImtQ-unsplash.jpg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: 100%;
  }
  .backgroundimg {
    position: absolute; height:150px; width:100%;
    
    background-color: rgba(var(--secondary),0.6);
  }
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/pumpsDiapo.json';
    import banner from '../components/bannerContactUs.vue';

  export default {
    name: 'pumpsDiapo',
    data: () => ({
      Dist: [
        {
          title:'submersible',
          img:'/Resources/images/Pumps/summersiblePump.jpg',
          hover:'Resources/images/',
          link:'/pompes#submersible'
        },
        {
          title:'égout',
          img:'/Resources/images/Pumps/pompeEgout.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_ProminentPetitV3-over.jpg',
          link:'/pompes#sewer'
        },
        {
          title:'puisard',
          img:'/Resources/images/Pumps/pompePuisard.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_Goulds-pumps_petitNEW-over.jpg',
          link:'/pompes#Sump'
        },
        {
          title:'piston',
          img:'/Resources/images/Pumps/pompePiston.jpg',
          hover:'Resources/images/Pumps/pumpTest.jpg',
          link:'/pompes#piston'
        },
        {
          title:'prominent',
          img:'/Resources/images/Pumps/pompeProminent.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_selWarwickPetitV20ver.jpg',
          link:'/pompes#prominent'
        },
        {
          title:'sécurité',
          img:'/Resources/images/Pumps/pompeSecurity.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_WaterRitePetitV2_over.jpg',
          link:'/pompes#security'
        },
        {
          title:'circulation chauffage',
          img:'/Resources/images/Pumps/pompeChauffage.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurViquaPetit_over.jpg',
          link:'/pompes#traffic'
        },
        {
          title:'diaphragme',
          img:'/Resources/images/Pumps/pompeDiaphragme.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurCorro_protecPetit_over.jpg',
          link:'/pompes#diaphragm'
        },
        {
          title:'transfert',
          img:'/Resources/images/Pumps/pompeTransfert.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurlittleGiantPetit_over.jpg',
          link:'/pompes#transfer'
        },
        {
          title:'piscine',
          img:'Resources/images/Pumps/pompePiscine.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurFranklinPetit-over.jpg',
          link:'/pompes#pool'
        },
        {
          title:'spa',
          img:'/Resources/images/Pumps/pompeSpa.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurSanifloPetit-over.jpg',
          link:'/pompes#spa'
        },
        {
          title:'irrigation',
          img:'/Resources/images/Pumps/pompeIrrigation.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMaximotion_over.jpg',
          link:'/pompes#irrigation'
        },
        {
          title:'booster pompe',
          img:'/Resources/images/Pumps/boosterPump.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMarathon-motors_over.jpg',
          link:'/pompes#booster'
        },
        {
          title:'pompe à main',
          img:'/Resources/images/Pumps/pompeMain.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMarathon-motors_over.jpg',
          link:'/pompes#handPump'
        },
        {
          title:'turbine',
          img:'/Resources/images/Pumps/turbinePump.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMarathon-motors_over.jpg',
          link:'/pompes#turbine'
        }/*,
        {
          title:'pompe de puits',
          img:'/Resources/images/Pumps/pompeDePuitPressionConstante.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMarathon-motors_over.jpg',
          link:'/pompes#wellPump'
        }*/
      ],
      columns: 3,
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
      this.changeColumns();
    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    computed: {

    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      }
    },
    component: {
      banner
    }
    
  }
</script>